import { Gateway } from "@lernetz/svelte-model-api";
import type { SearchResult } from "@lernetz/svelte-search";
import type { CollectionMaterial, Progress, Material } from "./models";
import { data } from "../services";
import { authentication } from "@microsoft/teams-js";

export interface SearchParams {
    query: string;
    sort: string;
    offset: number;
    limit: number;
    filters: Record<string, string[]>;
    progress?: boolean;
}

export type MaterialSearchParams = {
    scope?: "my" | "public";
    query: string;
    sort: string;
    pageSize: number;
    page: number;
    lang: string;
    filters: Record<string, string[]>;
    progress?: boolean;
};


export type MaterialSearchMetadata = {
    facetDistribution: Record<string, Record<string, number>>;
};

export type MaterialSearchResponse = SearchResult<CollectionMaterial, MaterialSearchMetadata>;

export class FlinkGateway extends Gateway {

    async material( publicId: string ) {
        let headers = await this.prepareHeaders();
        return this.base.jsonApi( `/s/${ publicId }/api/material`, {}, headers );
    }

    async searchCollectionMaterials( collectionPublicId: string, searchParam: SearchParams ) {
        let headers = await this.prepareHeaders();
        return this.base
            .jsonApi( `s/${ collectionPublicId }/api/search`, searchParam, headers )
            .then<MaterialSearchResponse>( this.in );
    }

    async lastVisited() {
        let headers = await this.prepareHeaders();
        return this.base
            .jsonApi( `api/lastVisited/`, { limit: 3, modelName: "App\\Models\\Material" }, headers )
            .then<Progress[]>( this.in );
    }

    async shareProgress( publicId: string ) {
        let headers = await this.prepareHeaders();
        return this.base
            .jsonApi( `api/shareProgress/`, { publicId }, headers )
            .then<Progress>( this.in );
    }

    async shareProgresssAverage( publicIds: string[] ) {
        let headers = await this.prepareHeaders();
        return this.base
            .jsonApi( `api/shareProgressesAverage/`, { publicIds }, headers )
            .then<{ average: number; }>( this.in );
    }

    private async prepareHeaders() {
        let token = await this.getAuthToken();
        return { Accept: "application/json", "Content-Type": "application/json", Authorization: "Bearer " + token };
    }

    private async getAuthToken() {
        if( data.env == 'local' ) {
            // TODO get token from backend
            // teams
            return "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ik1HTHFqOThWTkxvWGFGZnBKQ0JwZ0I0SmFLcyJ9.eyJhdWQiOiJiZTY0ZTM5YS1lYmJmLTRmMzUtYTU5NC02NTg1ZmIyZmY2OTkiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vZjhjNGVlZjItODYyYS00MjExLWE5OWUtZDQwY2Y2MWY5YmM3L3YyLjAiLCJpYXQiOjE3MjAxMDIyMzksIm5iZiI6MTcyMDEwMjIzOSwiZXhwIjoxNzIwMTA3MzU5LCJhaW8iOiJBV1FBbS84WEFBQUFMTXIwbVB3aUNBV2lIUTRNWTNpOFhrQysydDdadURKcEtGUk1wRUl3WVJNRnpaWTZOc3hXaDY5WjN0OVIvU3kxeE9UZWFaeHFTWFJLZTFyVkFlQWxRelErMXlVZ2h1Mkc3R1IwaVBJYjc0RWRrZG5nT0R3R3JVTDk5eXA3NStrcyIsImF6cCI6IjVlM2NlNmMwLTJiMWYtNDI4NS04ZDRiLTc1ZWU3ODc4NzM0NiIsImF6cGFjciI6IjAiLCJlbWFpbCI6InRlc3QudXNlckBnZWJhZXVkZWh1ZWxsZW5wbGFuZXIuc3dpc3MiLCJuYW1lIjoiVGVzdCBVc2VyIiwib2lkIjoiZTZhZDQ3YTEtOTIxZC00ZjI1LWEzODYtMDVmMzdkMWU2ZDE1IiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdC51c2VyQGdlYmFldWRlaHVlbGxlbnBsYW5lci5zd2lzcyIsInJoIjoiMC5BYTRBOHU3RS1DcUdFVUtwbnRRTTloLWJ4NXJqWkw2XzZ6VlBwWlJsaGZzdjlwbXVBSlUuIiwic2NwIjoiYWNjZXNzIiwic3ViIjoiS0dEd3ZKbS1IMTZSSlVuMDRTTk1fV1hZcDNHYXJyMFJTbG9HbXlsbzlkdyIsInRpZCI6ImY4YzRlZWYyLTg2MmEtNDIxMS1hOTllLWQ0MGNmNjFmOWJjNyIsInV0aSI6IkppTU94Z0QyU2ttaVI2Qm1jWEt2QUEiLCJ2ZXIiOiIyLjAifQ.vwkSyENzJ4lJiTyZrmak9GmLXSvZqhMBqfK-G2QP62TIQAJDT747mBxlBcuGeLOV1ALZG1OsJoGPZNiiz7Rpwi3S2cTxHXDBtq-XNjixWvSkl31eNUJLsjgOXV-5RuJBPsQxlxGKX-rbcxiFhckk3etwY0GCtL4tl2x_4dO2GIj-FQ12KJftRHudTzxcpPyE6glBcVG-GEubMW_26k8tj0D0pMDvbyrbuCZBVFSUpAKz2vdvlN5kkht09opoRtqPiDra50ySbZaMiogNjG8CTQTz73O54GApImckrGYvZXEIgxLHzAl1H5zOyaSuoFnfCS3-__PV5N11vKY2CXlQcQ";
        } else {
            return await authentication.getAuthToken();
        }
    }

}



